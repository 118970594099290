<template>
  <div class="manage-agency-users animatedBox">
    <div class="container fluid">
      <!-- =========================== Filter ============================ -->
      <filter-panel
        v-model="isFilterActive"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Agency User Name"
            name="name"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter['actualName[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Actual Name"
            name="actualName"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-select
            v-model="filter['agencyBranch:id']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Select Branch"
            :options="branchOptions"
          >
          </fd-select>
          <fd-input
            v-model="filter['email']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Email"
            name="email"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter['contact']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Contact"
            name="contact"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
        </div>
      </filter-panel>
      <!-- ========================= Table Listing ========================= -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="agencyUserTableColumns"
          :rows="agencyUserTableData"
          :totalRows="agencyUserTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="openAgencyUserDetail"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <!-- Table Actions -->
          <div slot="table-actions" class="p-2">
            <button
              v-if="canAdd"
              class="btn main mr-2"
              @click="toAddAgencyUser"
            >
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <!-- Table Buttons -->
            <span v-if="props.column.field == 'actions'" @click.stop>
              <dropdown-button v-if="canEdit" buttonClass="circle flat">
                <template #buttonContent>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <div class="card text-left">
                  <fd-list-item
                    class="cursor-pointer unselectable"
                    @click="toEditAgencyUser(props.row.id)"
                  >
                    <i class="fas fa-pen"></i>
                    <span class="ml-2">Edit Agency User</span>
                  </fd-list-item>
                  <fd-list-item
                    v-if="props.row.id !== $auth.user().id"
                    class="cursor-pointer unselectable"
                    @click="
                      openToggleAgencyUserStatus(
                        props.row.id,
                        props.row.isActive
                      )
                    "
                  >
                    <i class="fas fa-ban"></i>
                    <span class="ml-2">{{
                      props.row.isActive ? "Ban User" : "Unban User"
                    }}</span>
                  </fd-list-item>
                </div>
              </dropdown-button>
            </span>
            <!-- Active status -->
            <span v-else-if="props.column.field == 'isActive'">
              <span
                class="status mr-1"
                :class="getStatusClass(props.row.isActive)"
              >
                <i
                  class="fas"
                  :class="{
                    'fa-check': props.row.isActive,
                    'fa-times': !props.row.isActive
                  }"
                ></i> </span
            ></span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!-- ======================= Agency User Detail ======================== -->
    <modal v-model="isAgencyUserDetailShown">
      <agency-user-detail
        :detail="currentAgencyUserDetail"
        @close="isAgencyUserDetailShown = false"
      ></agency-user-detail>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem"),
    AgencyUserDetail: () => import("./AgencyUserDetail")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      canAdd: false,
      canEdit: false,
      canDelete: false,

      agencyUserTableData: [],
      agencyUserTablePagination: {},
      agencyUserTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Active",
          field: "isActive",
          tdClass: "text-center"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Actual Name",
          field: "actualName"
        },
        {
          label: "Branch",
          field: "agencyBranch.name"
        },
        {
          label: "Role",
          field: "rolePermission.name"
        },
        {
          label: "Email",
          field: "user.email"
        },
        {
          label: "Contact",
          field: (row) => this.$getUserPhoneNumber(row.user)
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      agencyOptions: [],
      branchOptions: [],
      isFilterActive: false,
      filter: {
        "name[partial]": "",
        "actualName[partial]": "",
        "agency:id": "",
        "agencyBranch:id": "",
        email: "",
        contact: ""
      },
      isAgencyUserDetailShown: false,
      currentAgencyUserDetail: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initData();
  },
  methods: {
    async initData() {
      try {
        this.$store.commit("setIsLoading", true);
        await Promise.all([
          this.getAccessPermission(),
          this.getAllBranches(),
          this.getAllAgencyUsers()
        ]);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async getAccessPermission() {
      this.canAdd = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "agencyUser.create"
      );
      this.canEdit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "agencyUser.update"
      );
      this.canDelete = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "agencyUser.delete"
      );
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ page: params.currentPage });
      this.getAllAgencyUsers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ perPage: params.currentPerPage });
      this.getAllAgencyUsers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddAgencyUser() {
      this.$router.push({ name: "ManageAgencyUsersAdd" });
    },
    toEditAgencyUser(id) {
      this.$router.push({ name: "ManageAgencyUsersEdit", params: { id: id } });
    },
    openToggleAgencyUserStatus(id, isActive) {
      // Set message
      let action;
      isActive ? (action = "ban") : (action = "unban");
      let message = `Are you sure you want to ${action} this user?`;

      this.$confirm({
        message: message,
        type: "alert"
      }).then((confirmed) => {
        if (confirmed) {
          this.confirmToggleStatus(id, !isActive);
        }
      });
    },
    async confirmToggleStatus(id, status) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.setAgencyUserStatus(id, status);
        this.getAllAgencyUsers().then(() => {
          this.$store.commit("setIsLoading", false);
        });
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
      }
    },
    openAgencyUserDetail(event) {
      this.currentAgencyUserDetail = event.row;
      this.isAgencyUserDetailShown = true;
    },
    // ===================== Filter related methods =====================
    async filterData() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      await this.getAllAgencyUsers();
      this.$store.commit("setIsLoading", false);
      return;
    },
    clearFilter() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllAgencyUsers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    // ========================== API Related =========================
    async getAllBranches() {
      try {
        let data = await this.$store.dispatch("manageBranch/getAllBranches", {
          perPage: 300
        });
        this.branchOptions = this._.cloneDeep(data.data);
      } catch (error) {
        throw error;
      }
    },
    async getAllAgencyUsers() {
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.$store.dispatch(
          "manageAgencyUsers/getAgencyUsers",
          {
            queries: filteredParam,
            page: this.tableParams.page,
            perPage: this.tableParams.perPage
          }
        );

        this.agencyUserTableData = this._.cloneDeep(data.data);
        this.agencyUserTablePagination = this._.cloneDeep(data.meta.pagination);
        return;
      } catch (error) {
        throw error;
      }
    },
    async setAgencyUserStatus(id, isActive) {
      try {
        let response = await this.$store.dispatch(
          "manageAgencyUsers/setAgencyUserStatus",
          { id: id, isActive: isActive }
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    // ======================== Miscellaneous =========================
    getStatusClass(status) {
      switch (status) {
        case true:
          return "active";
        case false:
          return "inactive";

        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-agency-users {
  .status {
    padding: 6px 12px;
    border-radius: 5px;
    width: fit-content;
    display: inline-block;
    &.active {
      background: $color-success;
      color: white;
    }
    &.inactive {
      background: $color-warn;
      color: #ffffff;
    }
  }
}
</style>
